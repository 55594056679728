<template>
  <div>
    <template>
      <a-descriptions :title="$t('Currency Info')" bordered>
        <a-descriptions-item v-for="key of keys" :key="key" :label="key">
          <a-input-number
            class="defaultNumber"
            :min="1"
            :max="10"
            disabled="true"
            :default-value="1"
          />
          {{ " / " }}
          <a-input-number
            :min="0"
            :max="10"
            :precision="2"
            :value="getDefaultCurrency(key)"
            @change="(e) => handleChangeCurrency({ value: e, key: key })"
          />
        </a-descriptions-item>
      </a-descriptions>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      keys: ["USD / PLN", "EUR / PLN", "EUR / USD"],
    };
  },
  computed: mapGetters([
    "getDefaultCurrency",
  ]),
  methods: mapActions(["handleChangeCurrency"]),
};
</script>

<style scoped>
.ant-descriptions {
  padding: 3% 3% 0% 3%;
}
.defaultNumber {
  width: 30px;
}
</style>
