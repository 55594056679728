<template>
  <div>
    <a-table
      :columns="columns"
      style="margin: 3%"
      :data-source="getProducts"
      bordered
      :scroll="{ x: true }"
      :pagination="false"
    >
      <template v-for="item of titleSlots" :slot="item.title">
        <div :key="item.key">
          <span
            >{{ $t(item.title) }}
            <a-select
              v-if="['SELLING_PRICE', 'MARGE', 'SUM_MARGE'].includes(item.key)"
              :disabled="getExistCurrency"
              default-value="PLN"
              :value="getCurrencyColumn(item.key)"
              @change="
                (e) =>
                  handleChangeSelect({
                    value: e,
                    key: item.key,
                  })
              "
              style="width: 80px; margin-left: 10px"
            >
              <a-select-option value="PLN"> PLN </a-select-option>
              <a-select-option value="USD"> USD </a-select-option>
              <a-select-option value="EUR"> EUR </a-select-option>
            </a-select>
          </span>
        </div>
      </template>

      <template
        v-for="col of columns"
        :slot="col.dataIndex"
        slot-scope="text, record, index"
      >
        <div :key="col.dataIndex" style="display: flex">
          <a-input
            :precision="2"
            :style="
              getLastChanged(index).includes(col.dataIndex) &&
              col.dataIndex != 'QUANTITY'
                ? 'background-color: lemonchiffon;'
                : getLastChangedPurchase(index).includes(col.dataIndex)
                ? 'background-color: #f5f5f5;'
                : 'background-color: white;'
            "
            :value="round10(text, -2) || ''"
            @blur="
              () =>
                handleChangeBlur({
                  column: col.dataIndex,
                  index,
                  vm: vm,
                })
            "
            @change="
              (e) =>
                handleChange({
                  value: e.target.value,
                  column: col.dataIndex,
                  index,
                  vm: vm,
                })
            "
          />
          <a-select
            style="margin-left: 5px; width: 90px"
            v-if="
              ![
                'SELLING_PRICE',
                'SUM_MARGE',
                'MARGE',
                'QUANTITY',
                'MARGE_PERCENT',
                'ARCHITECT',
              ].includes(col.dataIndex)
            "
            default-value="PLN"
            @change="
              (e) =>
                handleChangeSelectRow({
                  value: e,
                  column: col.dataIndex,
                  index,
                  vm: vm,
                })
            "
            :value="getCurrencyColumnRow(col.dataIndex, index)"
          >
            <a-select-option value="PLN"> PLN </a-select-option>
            <a-select-option value="USD"> USD </a-select-option>
            <a-select-option value="EUR"> EUR </a-select-option>
          </a-select>
        </div>
      </template>

      <template slot="action" slot-scope="text, record, index">
        <div>
          <a @click="() => saveProductInfo({ record, index, vm: vm })" hidden>
            <a-icon type="save" />
          </a>
          <a-popconfirm
            :title="$t('Delete info?')"
            :ok-text="$t('Yes')"
            :cancel-text="$t('No')"
            @confirm="removeProductInfo({ record, vm: vm })"
          >
            <a-button type="link">
              <a-icon type="delete" :style="{ color: '#ff4d4f' }" />
            </a-button>
          </a-popconfirm>
        </div>
      </template>

      <template slot="footer">
        <a-descriptions bordered size="small" style="width: 100%">
          <a-descriptions-item :label="$t('Total amount')">
            {{
              round10(getTotalSellingPrice, -2) +
              " " +
              getCurrencyColumn("SELLING_PRICE")
            }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('Margin amount')">
            {{
              round10(getTotalMarge, -2) + " " + getCurrencyColumn("SUM_MARGE")
            }}
          </a-descriptions-item>
        </a-descriptions>
        <div>
          <a-button
            type="primary"
            @click="() => saveProductInfo(vm)"
            style="height: 38px; width: 161px; margin-left: 10px"
          >
            {{ $t("Save") }}
          </a-button>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { round10 } from "@/math";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      round10,
      vm: this,
      titleSlots: [
        { title: "Selling price", key: "SELLING_PRICE" },
        { title: "Product purchase price", key: "PR_PURCHASE_PRICE" },
        { title: "Price of transport", key: "TRANSPORT_PRICE" },
        { title: "Packing price", key: "PACKING_PRICE" },
        { title: "Purchase price", key: "PURCHASE_PRICE" },
        { title: "Marge", key: "MARGE" },
        { title: "Summ marge", key: "SUM_MARGE" },
      ],
      columns: [
        {
          title: this.$t("Product"),
          dataIndex: "PRODUCT_NAME",
          slots: { title: "PRODUCT_NAME" },
          key: 1,
          fixed: "left",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "QUANTITY",
          key: 2,
          slots: { title: "Quantity" },
          scopedSlots: { customRender: "QUANTITY" },
        },
        {
          dataIndex: "SELLING_PRICE",
          key: 3,
          slots: { title: "Selling price" },
          scopedSlots: { customRender: "SELLING_PRICE" },
        },
        {
          dataIndex: "PR_PURCHASE_PRICE",
          key: 4,
          slots: { title: "Product purchase price" },
          scopedSlots: { customRender: "PR_PURCHASE_PRICE" },
        },
        {
          dataIndex: "TRANSPORT_PRICE",
          key: 5,
          slots: { title: "Price of transport" },
          scopedSlots: { customRender: "TRANSPORT_PRICE" },
        },
        {
          dataIndex: "PACKING_PRICE",
          key: 6,
          slots: { title: "Packing price" },
          scopedSlots: { customRender: "PACKING_PRICE" },
        },
        {
          dataIndex: "PURCHASE_PRICE",
          key: 7,
          slots: { title: "Purchase price" },
          scopedSlots: { customRender: "PURCHASE_PRICE" },
        },
        {
          title: this.$t("Marge %"),
          dataIndex: "MARGE_PERCENT",
          key: 10,
          slots: { title: "Marge %" },
          scopedSlots: { customRender: "MARGE_PERCENT" },
        },
        {
          dataIndex: "MARGE",
          key: 8,
          slots: { title: "Marge" },
          scopedSlots: { customRender: "MARGE" },
        },
        {
          dataIndex: "SUM_MARGE",
          key: 9,
          slots: { title: "Summ marge" },
          scopedSlots: { customRender: "SUM_MARGE" },
        },
        {
          title: this.$t("Action"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  computed: mapGetters([
    "getProducts",
    "getLastChanged",
    "getTotalMarge",
    "getTotalSellingPrice",
    "getLastChangedPurchase",
    "getExistCurrency",
    "getCurrencyColumn",
    "getCurrencyColumnRow",
  ]),
  methods: mapActions([
    "getDealProducts",
    "saveProductInfo",
    "handleChange",
    "removeProductInfo",
    "handleChangeBlur",
    "handleChangeSelect",
    "handleChangeSelectRow",
  ]),
  mounted() {

    this.getDealProducts();
  },
};
</script>

<style>
.ant-table-footer {
  display: flex !important;
}
</style>
