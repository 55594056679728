<template>
  <div id="licenses">
    <div style="display: flex; align-items: center; margin-bottom: 30px">
      <a-progress
        type="dashboard"
        :percent="getNumberDaysLeft.percent"
        :format="(percent) => `${getNumberDaysLeft.days} ${$t('Days')}`"
      />
      <div style="margin-left: 40px; margin-right: 40px; width: 100%">
        <h3>{{ $t("Enter your license key:") }}</h3>
        <a-input-search
          size="large"
          @search="addLicenseKey"
          :enter-button="$t('Send')"
          :defaultValue="getLicenseKey"
          :disabled="checkLicenseKey"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-search>
      </div>
      <a-card v-if="attention" class="attention" :title="$t('Attention!!!')">
        {{
          $t(
            "The application works only from tab built in Deal menu. If you use apps menu to open the app, you get only to app settings."
          )
        }}
      </a-card>
    </div>

    <div>
      <h3>{{ $t("Info") }}</h3>

      <span>{{
        $t("If you do not have a license key, you can purchase it here:")
      }}</span>
      <a
        href="https://my.support.pl/cart.php?a=add&pid=91
"
        target="_blank"
      >
        {{ $t("License key") }} </a
      ><br />
      <span>{{ $t("If you have questions:") }}</span>
      <a
        href="https://my.support.pl/knowledgebase/27/Calculator-in-Deals-or-Kalkulator-w-Deal?language=english"
        target="_blank"
      >
        my.support.pl
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      attention: false,
    };
  },
  computed: {
    ...mapGetters(["getNumberDaysLeft", "getLicenseKey", "checkLicenseKey"]),
  },
  methods: {
    ...mapActions(["updateLicenseKey"]),
    async addLicenseKey(key) {
      const domain = BX24.getDomain();

      let data = {
        method: "activateLicense",
        licenseKey: key,
        domain,
      };

      const res = await fetch(
        "https://calculator.app.support.pl/api/db/licenses.php",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (res.ok && res.status == 202) {
        let response = await res.json();
        this.updateLicenseKey(response.license);
        this.$notification.success({
          message: this.$t("App successfully activated!"),
          // description: this.$t("App successfully activated!"),
        });
      } else {
        this.$notification.error({
          message: this.$t("Application has not been activated!"),
          // description: this.$t("Application has not been activated!"),
        });
      }
    },
  },
  mounted() {
    let placement = BX24.placement.info();
    if (placement.placement != "CRM_DEAL_DETAIL_TAB") {
      this.attention = true;
    }
  },
};
</script>

<style>
#licenses {
  padding: 3%;
}
.attention .ant-card-head-title {
  color: red;
}
</style>
