import { round10 } from "@/math";

export default {
    state: {
        products: [],
        lastChanged: [],
        lastChangedSplice: [],
        changedPurchasePrice: [],
        currencyColumns: [],
        currencyColumnsRow: [],
        temporaryData: []
    },
    getters: {
        getProducts(state) {
            return state.products;
        },
        getLastChanged: (state) => (index) => {
            if (!(index in state.lastChangedSplice)) {
                return ["SELLING_PRICE"]
            } else {
                return state.lastChangedSplice[index];
            }
        },
        getLastChangedPurchase: (state) => (index) => {
            return state.changedPurchasePrice[index] || [];
        },
        getTotalMarge(state) {
            let summMarge = 0;
            for (let item of state.products) summMarge += +item['SUM_MARGE']
            return summMarge;
        },
        getTotalSellingPrice(state) {
            let summSellingPrice = 0;
            for (let item of state.products) summSellingPrice += +item['SELLING_PRICE']
            return summSellingPrice;
        },
        getCurrencyColumn: (state) => (index) => {
            return state.currencyColumns[index] || 'PLN'
        },
        getCurrencyColumnRow: (state) => (field, index) => {
            return state.currencyColumnsRow[index][field] || 'PLN'
        },
    },
    mutations: {
        updateProducts(state, products) {
            state.products = products;
            for (let item in products) {
                // console.log(products[item], 'fds');
                state.currencyColumnsRow[item] = Object.assign({}, state.currencyColumnsRow[item], { 'PACKING_PRICE': products[item]['CURRENCY_PACKING_PRICE'] || "PLN" })
                state.currencyColumnsRow[item] = Object.assign({}, state.currencyColumnsRow[item], { 'PR_PURCHASE_PRICE': products[item]['CURRENCY_PR_PURCHASE_PRICE'] || "PLN" })
                state.currencyColumnsRow[item] = Object.assign({}, state.currencyColumnsRow[item], { 'PURCHASE_PRICE': products[item]['CURRENCY_PURCHASE_PRICE'] || "PLN" })
                state.currencyColumnsRow[item] = Object.assign({}, state.currencyColumnsRow[item], { 'TRANSPORT_PRICE': products[item]['CURRENCY_TRANSPORT_PRICE'] || "PLN" })

                // state.temporaryData[item] = Object.assign({}, state.temporaryData[item], { value: +products[item]['PR_PURCHASE_PRICE'], column: 'PR_PURCHASE_PRICE', index: item })

                if (products[item].PR_PURCHASE_PRICE != "" || products[item].TRANSPORT_PRICE != "" || products[item].PACKING_PRICE != "") {
                    if (products[item].PR_PURCHASE_PRICE == "0" && products[item].TRANSPORT_PRICE == "0" && products[item].PACKING_PRICE == "0") {
                        state.lastChanged[item] = ['SELLING_PRICE', 'PURCHASE_PRICE']
                        state.temporaryData[item] = Object.assign({}, state.temporaryData[item], { value: +products[item]['PURCHASE_PRICE'], column: 'PURCHASE_PRICE', index: item })

                    } else {
                        state.lastChanged[item] = ['SELLING_PRICE', 'PR_PURCHASE_PRICE']
                        state.temporaryData[item] = Object.assign({}, state.temporaryData[item], { value: +products[item]['PR_PURCHASE_PRICE'], column: 'PR_PURCHASE_PRICE', index: item })

                    }
                } else {
                    state.lastChanged[item] = ['SELLING_PRICE', 'PURCHASE_PRICE']
                    state.temporaryData[item] = Object.assign({}, state.temporaryData[item], { value: +products[item]['PURCHASE_PRICE'], column: 'PURCHASE_PRICE', index: item })

                }
                // state.lastChanged[item] =  || products[item].PR_PURCHASE_PRICE != "0" || products[item].TRANSPORT_PRICE != "0" || products[item].PACKING_PRICE != "0" ? ['SELLING_PRICE', 'PR_PURCHASE_PRICE'] : ['SELLING_PRICE', 'PURCHASE_PRICE']
            }

        },
        updateProduct(state, obj) {
            state.products[obj.index][obj.column] = obj.value
        },
        calculations(state, obj) {

            // state.temporaryData[obj.index] = Object.assign({}, state.temporaryData[obj.index], { [obj.column]: obj.value })
            // state.temporaryData[obj.index] = obj
            state.temporaryData[obj.index] = obj

            if (!(obj.index in state.lastChanged)) {
                state.lastChanged[obj.index] = ["SELLING_PRICE"]
            }

            let arrayPurchasePrice = ['PR_PURCHASE_PRICE', 'TRANSPORT_PRICE', 'PACKING_PRICE'];

            if (obj.column != state.lastChanged[obj.index][0] && !arrayPurchasePrice.includes(obj.column)) {
                state.lastChanged[obj.index].unshift(obj.column)
            }

            if (obj.column == 'PURCHASE_PRICE') {
                state.products[obj.index]['PR_PURCHASE_PRICE'] = state.products[obj.index]['TRANSPORT_PRICE'] = state.products[obj.index]['PACKING_PRICE'] = 0
                state.changedPurchasePrice[obj.index] = ['PR_PURCHASE_PRICE', 'TRANSPORT_PRICE', 'PACKING_PRICE']
            }


            if (arrayPurchasePrice.includes(obj.column)) {

                state.changedPurchasePrice[obj.index] = []
                state.products[obj.index]['PURCHASE_PRICE'] = +updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'PR_PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'TRANSPORT_PRICE') + +updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'PACKING_PRICE')
                let changedPR = state.lastChanged[obj.index].slice()
                if (changedPR.length > 1) changedPR.splice(2);
                if (changedPR[0] != 'PURCHASE_PRICE') state.lastChanged[obj.index].unshift('PURCHASE_PRICE')
            }

            var changed = state.lastChanged[obj.index].slice()



            if (changed.length > 1) changed.splice(2);

            if (changed.includes("SELLING_PRICE") && changed.includes("PURCHASE_PRICE")) {
                state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                // state.products[obj.index]['MARGE_PERCENT'] = 100 - (100 / (updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') / state.products[obj.index]['PURCHASE_PRICE']))
                // state.products[obj.index]['MARGE'] = (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') * state.products[obj.index]['MARGE_PERCENT'] - updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') * state.products[obj.index]['ARCHITECT']) / 100
                state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
            }
            if (changed.includes("SELLING_PRICE") && changed.includes("MARGE")) {
                state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'MARGE')
                state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                // state.products[obj.index]['ARCHITECT'] = 0
            }
            if (changed.includes("PURCHASE_PRICE") && changed.includes("MARGE")) {
                state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'SELLING_PRICE', 'MARGE')
                state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                // state.products[obj.index]['ARCHITECT'] = 0
            }
            if (changed.includes("QUANTITY")) {
                state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                state.lastChanged[obj.index].shift()
            }

            if (changed.includes("SUM_MARGE") && changed.includes("SELLING_PRICE")) {
                state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SUM_MARGE') / state.products[obj.index]['QUANTITY']
                state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'MARGE')
                state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                // state.products[obj.index]['ARCHITECT'] = 0
            }
            if (changed.includes("SUM_MARGE") && changed.includes("PURCHASE_PRICE")) {
                state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SUM_MARGE') / state.products[obj.index]['QUANTITY']
                state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'SELLING_PRICE', 'MARGE')
                state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                // state.products[obj.index]['ARCHITECT'] = 0
            }
            if (changed.includes("MARGE_PERCENT") && changed.includes("SELLING_PRICE")) {
                state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - (updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') / 100 * state.products[obj.index]['MARGE_PERCENT'])
                // state.products[obj.index]['MARGE'] = (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') * state.products[obj.index]['MARGE_PERCENT'] - updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') * state.products[obj.index]['ARCHITECT']) / 100
                state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
            }
            if (changed.includes("MARGE_PERCENT") && changed.includes("PURCHASE_PRICE")) {
                // let before = this.state.products.currencyColumnsRow[obj.index]['PURCHASE_PRICE']
                // let after = this.state.products.currencyColumns['SELLING_PRICE']
                // let prepareCurrency = before == after ? 1 : this.state.currency.currencySettings[before + ' / ' + after].course
                state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +(updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') / 100 * state.products[obj.index]['MARGE_PERCENT'])
                // state.products[obj.index]['SELLING_PRICE'] = this.state.products.products[obj.index]['PURCHASE_PRICE'] * (prepareCurrency / (1 - (state.products[obj.index]['MARGE_PERCENT'] / 100)))
                state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                // state.products[obj.index]['MARGE'] = (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') * state.products[obj.index]['MARGE_PERCENT'] - updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') * state.products[obj.index]['ARCHITECT']) / 100
                state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
            }


            var arrayWithoutMarge = []

            if (changed.includes("SUM_MARGE") && changed.includes("MARGE")) {
                arrayWithoutMarge = [state.lastChanged[obj.index][0]]
                for (let item of state.lastChanged[obj.index]) {
                    if (item == "SELLING_PRICE" || item == "PURCHASE_PRICE") {
                        arrayWithoutMarge.unshift(item)
                        break;
                    }
                }

                if (arrayWithoutMarge.includes('SELLING_PRICE') && arrayWithoutMarge.includes('MARGE')) {
                    state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'MARGE')
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                }
                if (arrayWithoutMarge.includes('SELLING_PRICE') && arrayWithoutMarge.includes('SUM_MARGE')) {
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SUM_MARGE') / state.products[obj.index]['QUANTITY']
                    state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'MARGE')
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                }
                if (arrayWithoutMarge.includes('PURCHASE_PRICE') && arrayWithoutMarge.includes('SUM_MARGE')) {
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SUM_MARGE') / state.products[obj.index]['QUANTITY']
                    state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'SELLING_PRICE', 'MARGE')
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                }
                if (arrayWithoutMarge.includes('PURCHASE_PRICE') && arrayWithoutMarge.includes('MARGE')) {
                    state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'SELLING_PRICE', 'MARGE')
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                }
                // state.products[obj.index]['ARCHITECT'] = 0
            }

            if (changed.includes("MARGE_PERCENT") && changed.includes("SUM_MARGE")) {
                arrayWithoutMarge = [state.lastChanged[obj.index][0]]
                for (let item of state.lastChanged[obj.index]) {
                    if (item == "SELLING_PRICE" || item == "PURCHASE_PRICE") {
                        arrayWithoutMarge.unshift(item)
                        break;
                    }
                }

                if (arrayWithoutMarge.includes('SELLING_PRICE') && arrayWithoutMarge.includes('MARGE_PERCENT')) {
                    state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - (updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') / 100 * state.products[obj.index]['MARGE_PERCENT'])
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                }
                if (arrayWithoutMarge.includes('SELLING_PRICE') && arrayWithoutMarge.includes('SUM_MARGE')) {
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SUM_MARGE') / state.products[obj.index]['QUANTITY']
                    state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'MARGE')
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                }
                if (arrayWithoutMarge.includes('PURCHASE_PRICE') && arrayWithoutMarge.includes('SUM_MARGE')) {
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SUM_MARGE') / state.products[obj.index]['QUANTITY']
                    state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'SELLING_PRICE', 'MARGE')
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                }
                if (arrayWithoutMarge.includes('PURCHASE_PRICE') && arrayWithoutMarge.includes('MARGE_PERCENT')) {
                    state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +(updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') / 100 * state.products[obj.index]['MARGE_PERCENT'])
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                }
                // state.products[obj.index]['ARCHITECT'] = 0
            }

            if (changed.includes("MARGE") && changed.includes("MARGE_PERCENT")) {
                arrayWithoutMarge = [state.lastChanged[obj.index][0]]
                for (let item of state.lastChanged[obj.index]) {
                    if (item == "SELLING_PRICE" || item == "PURCHASE_PRICE") {
                        arrayWithoutMarge.unshift(item)
                        break;
                    }
                }

                if (arrayWithoutMarge.includes('SELLING_PRICE') && arrayWithoutMarge.includes('MARGE')) {
                    state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'MARGE')
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                }
                if (arrayWithoutMarge.includes('SELLING_PRICE') && arrayWithoutMarge.includes('MARGE_PERCENT')) {
                    state.products[obj.index]['PURCHASE_PRICE'] = updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') - (updateCurrency(this.state, obj, 'PURCHASE_PRICE', 'SELLING_PRICE') / 100 * state.products[obj.index]['MARGE_PERCENT'])
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                }
                if (arrayWithoutMarge.includes('PURCHASE_PRICE') && arrayWithoutMarge.includes('MARGE_PERCENT')) {
                    state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +(updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') / 100 * state.products[obj.index]['MARGE_PERCENT'])
                    state.products[obj.index]['MARGE'] = updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') - updateCurrency(this.state, obj, 'MARGE', 'PURCHASE_PRICE')
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                }
                if (arrayWithoutMarge.includes('PURCHASE_PRICE') && arrayWithoutMarge.includes('MARGE')) {
                    state.products[obj.index]['SELLING_PRICE'] = +updateCurrency(this.state, obj, 'SELLING_PRICE', 'PURCHASE_PRICE') + +updateCurrency(this.state, obj, 'SELLING_PRICE', 'MARGE')
                    state.products[obj.index]['MARGE_PERCENT'] = (100 / (updateCurrency(this.state, obj, 'MARGE', 'SELLING_PRICE') / state.products[obj.index]['MARGE']))
                    state.products[obj.index]['SUM_MARGE'] = updateCurrency(this.state, obj, 'SUM_MARGE', 'MARGE') * state.products[obj.index]['QUANTITY']
                }
                // state.products[obj.index]['ARCHITECT'] = 0
            }

            state.lastChangedSplice[obj.index] = arrayWithoutMarge.length == 0 ? changed.slice() : arrayWithoutMarge.slice();
        },
        updateCurrencyColumns(state, obj) {
            state.currencyColumns = Object.assign({}, state.currencyColumns, { [obj.key]: obj.value })
        },
        updateCurrencyColumnsRow(state, obj) {
            if (obj.index in state.currencyColumnsRow) {
                state.currencyColumnsRow[obj.index] = Object.assign({}, state.currencyColumnsRow[obj.index], { [obj.column]: obj.value })
            } else {
                state.currencyColumnsRow = Object.assign({}, state.currencyColumnsRow, { [obj.index]: { [obj.column]: obj.value } })
            }
            state.products[obj.index]['CURRENCY_' + obj.column] = obj.value
        },
        updateAllCurrencyTable(state, obj) {
            let arrayCurrencyKey = ['MARGE', 'PACKING_PRICE', 'PR_PURCHASE_PRICE', 'PURCHASE_PRICE', 'SELLING_PRICE', 'SUM_MARGE', 'TRANSPORT_PRICE'];
            for (let item of arrayCurrencyKey) {
                state.currencyColumns[item] = obj[item]
            }
        }
    },
    actions: {
        handleChangeBlur(ctx, obj) {
            if (ctx.state.products[obj.index]['MARGE_PERCENT'] < 5) {
                obj.vm.$message.error(obj.vm.$t('Caution !!! The margin cannot be less than 5%.'));
            }
        },
        handleChange(ctx, obj) {
            ctx.commit("updateProduct", obj)
            ctx.commit("calculations", obj)
        },
        handleChangeSelect(ctx, obj) {
            ctx.commit("updateCurrencyColumns", obj)
            for (let item in ctx.state.temporaryData) {
                //console.log(ctx.state.temporaryData[item], 'item')
                ctx.commit("calculations", ctx.state.temporaryData[item])
            }
            //  ctx.commit("calculations", ctx.state.temporaryData)
        },
        handleChangeSelectRow(ctx, obj) {
            ctx.commit("updateCurrencyColumnsRow", obj)
            ctx.commit("calculations", ctx.state.temporaryData[obj.index])
        },
        async getDealProducts(ctx) {

            BX24.init(async () => {
                let info = BX24.placement.info()
                let auth = BX24.getAuth()

                const res = await fetch('https://calculator.app.support.pl/api/products/getProducts.php?domain=' + auth.domain + '&access_token=' + auth.access_token + '&ID=' + info.options.ID,
                    { method: 'GET' })

                if (res.ok && res.status != 204) {

                    const products = await res.json()

                    ctx.commit('updateProducts', products.products)
                    if (products.currencySettings) ctx.commit('updateAllCurrencyTable', products.currencySettings)
                    if (products.currencyCourse) ctx.commit('updateAllCurrency', products.currencyCourse)
                }

            });

        },
        // async saveProductInfo(ctx, { record, index, vm }) {
        //     record.key = index

        //     if (record.MARGE_PERCENT < 5) {
        //         vm.$message.error(vm.$t('Caution !!! The margin cannot be less than 5%.'));
        //     } else {
        //         BX24.init(async () => {

        //             let tableCurrency = {}

        //             for (let item in ctx.state.currencyColumns) {
        //                 tableCurrency[item] = ctx.state.currencyColumns[item];
        //             }

        //             let tableCurrencyCourse = {}

        //             for (let item in this.state.currency.currencySettings) {
        //                 tableCurrencyCourse[this.state.currency.currencySettings[item].defaultCurrecy + this.state.currency.currencySettings[item].currency] = this.state.currency.currencySettings[item];
        //             }

        //             let auth = BX24.getAuth()

        //             var data = {
        //                 auth: {
        //                     domain: auth.domain,
        //                     access_token: auth.access_token
        //                 },
        //                 record,
        //                 tableCurrency,
        //                 tableCurrencyCourse
        //             }

        //             const res = await fetch('https://calculator.app.support.pl/api/products/saveProduct.php', {
        //                 method: 'POST',
        //                 body: JSON.stringify(data)
        //             })

        //             if (res.ok && res.status != 204) {
        //                 vm.$message.success(vm.$t('Data saved successfully!'));
        //             } else {
        //                 vm.$message.error(vm.$t('Data not saved!'));
        //             }

        //         });
        //     }

        // },
        async saveProductInfo(ctx, { vm }) {

            BX24.init(async () => {

                let tableCurrency = {}

                for (let item in ctx.state.currencyColumns) {
                    tableCurrency[item] = ctx.state.currencyColumns[item];
                }

                let tableCurrencyCourse = {}

                for (let item in this.state.currency.currencySettings) {
                    tableCurrencyCourse[this.state.currency.currencySettings[item].defaultCurrecy + this.state.currency.currencySettings[item].currency] = this.state.currency.currencySettings[item];
                }

                let auth = BX24.getAuth()
                let info = BX24.placement.info()

                var data = {
                    auth: {
                        domain: auth.domain,
                        access_token: auth.access_token
                    },
                    record: ctx.state.products,
                    deal: info.options.ID,
                    tableCurrency,
                    tableCurrencyCourse
                }

                const res = await fetch('https://calculator.app.support.pl/api/products/saveProduct.php', {
                    method: 'POST',
                    body: JSON.stringify(data)
                })

                if (res.ok && res.status != 204) {
                    vm.$message.success(vm.$t('Data saved successfully!'));
                    let domain = BX24.getDomain()
                    let { options } = BX24.placement.info()
                    window.top.location = 'https://' + domain + '/crm/deal/details/' + options.ID + '/'
                } else {
                    vm.$message.error(vm.$t('Data not saved!'));
                }

            });

        },
        async removeProductInfo(ctx, { record, vm }) {

            for (let item in this.state.products.products) {
                if (this.state.products.products[item].ID == record.ID) {
                    this.state.products.products[item]['TRANSPORT_PRICE'] = "0"
                    this.state.products.products[item]['SUM_MARGE'] = "0"
                    this.state.products.products[item]['PURCHASE_PRICE'] = "0"
                    this.state.products.products[item]['PR_PURCHASE_PRICE'] = "0"
                    this.state.products.products[item]['PACKING_PRICE'] = "0"
                    this.state.products.products[item]['MARGE_PERCENT'] = "0"
                    this.state.products.products[item]['MARGE'] = "0"
                    break;
                }
            }

            BX24.init(async () => {

                let auth = BX24.getAuth()

                var data = {
                    auth: {
                        domain: auth.domain,
                        access_token: auth.access_token
                    },
                    record
                }

                const res = await fetch('https://calculator.app.support.pl/api/products/removeProduct.php', {
                    method: 'DELETE',
                    body: JSON.stringify(data)
                })

                if (res.ok && res.status != 204) {
                    vm.$message.success(vm.$t('Product information has been successfully deleted!'));
                } else {
                    vm.$message.error(vm.$t('Product information has not been deleted!'));
                }

            });
        }
    },
};

function updateCurrency(state, obj, resultCurrency, beforeUpdateCurrency) {

    // let prepareCurrency = state.products.currencyColumns[resultCurrency] + ' / ' + state.products.currencyColumns[beforeUpdateCurrency]
    let before = ['SELLING_PRICE', 'MARGE', 'SUM_MARGE'].includes(beforeUpdateCurrency) ? state.products.currencyColumns[beforeUpdateCurrency] : state.products.currencyColumnsRow[obj.index][beforeUpdateCurrency]
    let after = ['SELLING_PRICE', 'MARGE', 'SUM_MARGE'].includes(resultCurrency) ? state.products.currencyColumns[resultCurrency] : state.products.currencyColumnsRow[obj.index][resultCurrency]
    // let prepareCurrency = state.products.currencyColumns[beforeUpdateCurrency] + ' / ' + state.products.currencyColumns[resultCurrency]
    let prepareCurrency = before + ' / ' + after
    return before == after ? state.products.products[obj.index][beforeUpdateCurrency] : round10(state.products.products[obj.index][beforeUpdateCurrency] * state.currency.currencySettings[prepareCurrency].course, -2)
    // return state.products.currencyColumns[resultCurrency] == state.products.currencyColumns[beforeUpdateCurrency] ? state.products.products[obj.index][beforeUpdateCurrency] : round10(state.products.products[obj.index][beforeUpdateCurrency] * state.currency.currencySettings[prepareCurrency].course, -2)
}