import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import i18n from './i18n'
import 'ant-design-vue/dist/antd.css'
import {store} from './store';

Vue.config.productionTip = false
Vue.use(Antd)

new Vue({
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
