import { DateDiff } from "@/math";
import Vue from 'vue';
import Vuex from 'vuex';
import products from './modules/products';
import currency from './modules/currency';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    license: {}
  },
  getters: {
    getNumberDaysLeft(state) {
      let daysLeft = 0
      if (state.license.lic_key === null) {
        let days = DateDiff(new Date(state.license.trial_end_date), new Date())
        daysLeft = { percent: (Math.ceil(days) * 100 / 15).toFixed(2), days: Math.ceil(days) }
      } else {
        let days = DateDiff(new Date(state.license.lic_end_date), new Date())
        daysLeft = { percent: (Math.ceil(days) * 100 / 365).toFixed(2), days: Math.ceil(days) }
      }
      return daysLeft
    },
    getLicenseKey(state) {
      return state.license.lic_key
    },
    checkLicenseKey(state) {
      return state.license.lic_key !== null ? true : false
    }
  },
  mutations: {
    updateInfoAboutLicense(state, obj) {
      state.license = Object.assign({}, obj)
    }
  },
  actions: {
    addInfoAboutLicense(ctx, obj) {
      ctx.commit("updateInfoAboutLicense", obj)
    },
    updateLicenseKey(ctx, obj) {
      ctx.commit("updateInfoAboutLicense", obj)
    }
  },
  modules: {
    products,
    currency
  },
});