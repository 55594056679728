var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-table',{staticStyle:{"margin":"3%"},attrs:{"columns":_vm.columns,"data-source":_vm.getProducts,"bordered":"","scroll":{ x: true },"pagination":false},scopedSlots:_vm._u([_vm._l((_vm.columns),function(col){return {key:col.dataIndex,fn:function(text, record, index){return [_c('div',{key:col.dataIndex,staticStyle:{"display":"flex"}},[_c('a-input',{style:(_vm.getLastChanged(index).includes(col.dataIndex) &&
            col.dataIndex != 'QUANTITY'
              ? 'background-color: lemonchiffon;'
              : _vm.getLastChangedPurchase(index).includes(col.dataIndex)
              ? 'background-color: #f5f5f5;'
              : 'background-color: white;'),attrs:{"precision":2,"value":_vm.round10(text, -2) || ''},on:{"blur":function () { return _vm.handleChangeBlur({
                column: col.dataIndex,
                index: index,
                vm: _vm.vm,
              }); },"change":function (e) { return _vm.handleChange({
                value: e.target.value,
                column: col.dataIndex,
                index: index,
                vm: _vm.vm,
              }); }}}),(
            ![
              'SELLING_PRICE',
              'SUM_MARGE',
              'MARGE',
              'QUANTITY',
              'MARGE_PERCENT',
              'ARCHITECT' ].includes(col.dataIndex)
          )?_c('a-select',{staticStyle:{"margin-left":"5px","width":"90px"},attrs:{"default-value":"PLN","value":_vm.getCurrencyColumnRow(col.dataIndex, index)},on:{"change":function (e) { return _vm.handleChangeSelectRow({
                value: e,
                column: col.dataIndex,
                index: index,
                vm: _vm.vm,
              }); }}},[_c('a-select-option',{attrs:{"value":"PLN"}},[_vm._v(" PLN ")]),_c('a-select-option',{attrs:{"value":"USD"}},[_vm._v(" USD ")]),_c('a-select-option',{attrs:{"value":"EUR"}},[_vm._v(" EUR ")])],1):_vm._e()],1)]}}}),{key:"action",fn:function(text, record, index){return [_c('div',[_c('a',{attrs:{"hidden":""},on:{"click":function () { return _vm.saveProductInfo({ record: record, index: index, vm: _vm.vm }); }}},[_c('a-icon',{attrs:{"type":"save"}})],1),_c('a-popconfirm',{attrs:{"title":_vm.$t('Delete info?'),"ok-text":_vm.$t('Yes'),"cancel-text":_vm.$t('No')},on:{"confirm":function($event){return _vm.removeProductInfo({ record: record, vm: _vm.vm })}}},[_c('a-button',{attrs:{"type":"link"}},[_c('a-icon',{style:({ color: '#ff4d4f' }),attrs:{"type":"delete"}})],1)],1)],1)]}}],null,true)},[_vm._l((_vm.titleSlots),function(item){return _c('template',{slot:item.title},[_c('div',{key:item.key},[_c('span',[_vm._v(_vm._s(_vm.$t(item.title))+" "),(['SELLING_PRICE', 'MARGE', 'SUM_MARGE'].includes(item.key))?_c('a-select',{staticStyle:{"width":"80px","margin-left":"10px"},attrs:{"disabled":_vm.getExistCurrency,"default-value":"PLN","value":_vm.getCurrencyColumn(item.key)},on:{"change":function (e) { return _vm.handleChangeSelect({
                  value: e,
                  key: item.key,
                }); }}},[_c('a-select-option',{attrs:{"value":"PLN"}},[_vm._v(" PLN ")]),_c('a-select-option',{attrs:{"value":"USD"}},[_vm._v(" USD ")]),_c('a-select-option',{attrs:{"value":"EUR"}},[_vm._v(" EUR ")])],1):_vm._e()],1)])])}),_c('template',{slot:"footer"},[_c('a-descriptions',{staticStyle:{"width":"100%"},attrs:{"bordered":"","size":"small"}},[_c('a-descriptions-item',{attrs:{"label":_vm.$t('Total amount')}},[_vm._v(" "+_vm._s(_vm.round10(_vm.getTotalSellingPrice, -2) + " " + _vm.getCurrencyColumn("SELLING_PRICE"))+" ")]),_c('a-descriptions-item',{attrs:{"label":_vm.$t('Margin amount')}},[_vm._v(" "+_vm._s(_vm.round10(_vm.getTotalMarge, -2) + " " + _vm.getCurrencyColumn("SUM_MARGE"))+" ")])],1),_c('div',[_c('a-button',{staticStyle:{"height":"38px","width":"161px","margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function () { return _vm.saveProductInfo(_vm.vm); }}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }