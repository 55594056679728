<template>
  <div id="app">
    <a-locale-provider :locale="locale">
      <div>
        <a-tabs
          default-active-key="1"
          :activeKey="activeTab"
          @change="changeTab"
        >
          <a-tab-pane key="1" :disabled="checkLicense">
            <span slot="tab">
              <a-icon type="calculator" />
              {{ $t("Calculator") }}
            </span>
            <CurrencySettings />
            <Products :key="refresh" />
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="safety-certificate" />
              {{ $t("License") }}
            </span>
            <Licenses :key="refresh" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-locale-provider>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import pl_PL from "ant-design-vue/lib/locale-provider/pl_PL";
import ru_RU from "ant-design-vue/lib/locale-provider/ru_RU";
import en_GB from "ant-design-vue/lib/locale-provider/en_GB";
import Products from "./components/products.vue";
import CurrencySettings from "./components/currencySetting.vue";
import Licenses from "./components/licenses.vue";

export default {
  name: "App",
  components: {
    Products,
    CurrencySettings,
    Licenses,
  },
  data() {
    return {
      en_GB,
      pl_PL,
      ru_RU,
      refresh: 0,
      locale: en_GB,
      checkLicense: true,
      activeTab: "1",
    };
  },
  methods: {
    ...mapActions(["addInfoAboutLicense"]),
    changeTab(el) {
      this.activeTab = el;
    },
  },
  mounted() {
    BX24.init(async () => {
      let auth = BX24.getAuth();

      const res = await fetch(
        "https://calculator.app.support.pl/api/db/licenses.php?method=getInfoAboutLicense&domain=" +
          auth.domain
      );
      if (res.ok && res.status == 200) {
        let response = await res.json();

        this.addInfoAboutLicense(response.license);

        let date = new Date(response.license.trial_end_date);
        let dateLic = new Date(response.license.lic_end_date);

        let info = BX24.placement.info();
        if (info.placement != "DEFAULT") {
          if (response.license.lic_key !== null && dateLic >= new Date()) {
            this.checkLicense = false;
          } else if (date >= new Date()) {
            this.checkLicense = false;
          } else {
            this.activeTab = "2";
          }
        } else {
          this.activeTab = "2";
        }
      }

      this.$i18n.locale = BX24.getLang();
      this.refresh++;
      if (this.$i18n.locale == "en") {
        this.locale = en_GB;
      } else if (this.$i18n.locale == "pl") {
        this.locale = pl_PL;
      } else if (this.$i18n.locale == "ru") {
        this.locale = ru_RU;
      }
    });
  },
};
</script>

<style>
#root_block,
#root_block > .ant-spin-container {
  width: 100%;
}

.ant-tabs-bar {
  margin: 0 !important;
}
</style>
