// import { round10 } from "@/math";

export default {
    state: {
        currencySettings: []
    },
    getters: {
        getExistCurrency(state) {
            return !(state.currencySettings["PLN / USD"] && state.currencySettings["PLN / EUR"] && state.currencySettings["EUR / USD"]) && state.currencySettings["PLN / USD"] != 0 && state.currencySettings["PLN / EUR"] != 0 && state.currencySettings["EUR / USD"] != 0
        },
        getDefaultCurrency: (state) => (index) => {
            return state.currencySettings[index]?.course || 0
        },
    },
    mutations: {
        saveCurrency(state, obj) {

            switch (obj.key) {
                case "USD / PLN":
                    state.currencySettings = Object.assign({}, state.currencySettings, {
                        ["USD / PLN"]: { 'defaultCurrecy': 'USD', 'currency': 'PLN', 'unit': 1, 'course': obj.value },
                        ["PLN / USD"]: { 'defaultCurrecy': 'PLN', 'currency': 'USD', 'unit': 1, 'course': 1 / obj.value }
                    })
                    break;

                case "EUR / PLN":
                    state.currencySettings = Object.assign({}, state.currencySettings, {
                        ["EUR / PLN"]: { 'defaultCurrecy': 'EUR', 'currency': 'PLN', 'unit': 1, 'course': obj.value },
                        ["PLN / EUR"]: { 'defaultCurrecy': 'PLN', 'currency': 'EUR', 'unit': 1, 'course': 1 / obj.value }
                    })
                    break;

                case "EUR / USD":
                    state.currencySettings = Object.assign({}, state.currencySettings, {
                        ["EUR / USD"]: { 'defaultCurrecy': 'EUR', 'currency': 'USD', 'unit': 1, 'course': obj.value },
                        ["USD / EUR"]: { 'defaultCurrecy': 'USD', 'currency': 'EUR', 'unit': 1, 'course': 1 / obj.value }
                    })
                    break;
            }

        },
        updateAllCurrency(state, obj) {
            for (let item in obj) {
                state.currencySettings = Object.assign({}, state.currencySettings, {
                    [obj[item].defaultCurrecy + " / " + obj[item].currency]: { 'defaultCurrecy': obj[item].defaultCurrecy, 'currency': obj[item].currency, 'unit': 1, 'course': obj[item].course },
                    [obj[item].currency + " / " + obj[item].defaultCurrecy]: { 'defaultCurrecy': obj[item].currency, 'currency': obj[item].defaultCurrecy, 'unit': 1, 'course': 1 / obj[item].course }
                })
            }
        }
    },
    actions: {
        handleChangeCurrency(ctx, obj) {
            ctx.commit("saveCurrency", obj)
        }
    },
};